import { type Environment } from '@daangn/karrotbridge/lib/core/getCurrentEnvironment'
import { karrotAnalyticsPlugin } from '@daangn/stackflow-plugin-karrot-analytics'
import { vars } from '@seed-design/design-token'
import { basicUIPlugin, IconBack } from '@stackflow/plugin-basic-ui'
import { historySyncPlugin } from '@stackflow/plugin-history-sync'
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic'
import { stackflow } from '@stackflow/react'
import React from 'react'

import { karrotBridge } from '@src/bridge/bridge'
import { IS_ANDROID, STAGE } from '@src/constants'
import { ANDROID_ONLY_getSafeAreaInsetTopOverrideValue } from '@src/local-map-utils/safeAreaVariable'
import { getDeviceType } from '@src/ts-utils/deviceType'

import depthChangePlugin from './plugins/depthChange'
import { iOSScrollToTopPlugin } from './plugins/iosScrollToTopPlugin'
import ActivityNotFound from '../pages/NotFound'

/**
 * Stackflow는 웹뷰 내에서 Stack Navigation UI를 도와주는 도구에요.
 * 아래 웹사이트를 통해, 가이드 문서를 확인하실 수 있어요.
 * 웹사이트: https://stackflow.so
 * GitHub: https://github.com/daangn/stackflow
 */

export const stackflowThemeBy = (environment: Environment) => {
  if (environment === 'Cupertino') {
    return 'cupertino'
  }
  if (environment === 'Android') {
    return 'android'
  }
  // Web
  return 'android'
}

export const theme = getDeviceType() === 'ios' ? 'cupertino' : 'android'

const internalPages = () => {
  if (STAGE === 'development' || STAGE === 'alpha') {
    return {
      Test: React.lazy(() => import('../pages/_development/PageTest')),
    }
  }

  return {
    Test: React.lazy(() => import('../pages/home-activity')),
  }
}

const activities = {
  ...internalPages(),
  Home: React.lazy(() => import('../pages/home-activity')),
  OpenOnboardingBottom: React.lazy(
    () =>
      import(
        '@src/pages/open-onboarding-bottom-sheet/PageOpenOnboardingBottomSheet'
      )
  ),
  ModalsHome: React.lazy(() => import('src/pages/home-activity/modals')),
  UserLocationInMap: React.lazy(
    () => import('@src/pages/user-location-search/PageUserLocationInMap')
  ),
  UserLocationSearch: React.lazy(
    () => import('@src/pages/user-location-search/PageUserLocationSearch')
  ),
  UserLocationTermsBottomSheet: React.lazy(
    () =>
      import(
        '@src/pages/user-location-terms-bottom-sheet/PageUserLocationTermsBottomSheet'
      )
  ),
  UserLocationTermsDetail: React.lazy(
    () =>
      import(
        '@src/pages/user-location-terms-detail/PageUserLocationTermsDetail'
      )
  ),
  ActivityNotFound,
}

const { Stack, useFlow } = stackflow({
  transitionDuration: 270,
  activities: activities,
  plugins: [
    basicRendererPlugin(),
    basicUIPlugin({
      theme,
      backgroundColor: vars.$semantic.color.paperDefault,
      appBar: {
        borderColor:
          theme === 'cupertino'
            ? vars.$semantic.color.divider3
            : vars.$semantic.color.divider2,
        textColor: vars.$semantic.color.inkText,
        iconColor: vars.$semantic.color.inkText,
        minSafeAreaInsetTop:
          IS_ANDROID || ANDROID_ONLY_getSafeAreaInsetTopOverrideValue()
            ? `${ANDROID_ONLY_getSafeAreaInsetTopOverrideValue()}px`
            : undefined,
        closeButton: {
          renderIcon: () => <IconBack />,
          onClick() {
            karrotBridge.closeRouter({})
          },
        },
      },
    }),
    historySyncPlugin({
      routes: {
        Test: '/',
        Home: '/home',
        OpenOnboardingBottom: '/local-map-open',
        ModalsHome: '/home/modals/:modalType',
        UserLocationInMap: '/user-location',
        UserLocationSearch: '/user-location/search',
        UserLocationTermsBottomSheet: '/user-location/terms',
        UserLocationTermsDetail: '/user-location/terms/detail',
      },
      fallbackActivity: () => 'Home',
    }),
    karrotAnalyticsPlugin({
      bridge: karrotBridge,
      serviceName: 'local-map',
    }),
    depthChangePlugin({
      theme,
    }),
    iOSScrollToTopPlugin(),
  ],
})

export { Stack }
export type TypeUseFlow = typeof useFlow
export type ActivitiesKeyType = keyof typeof activities
